import { create } from "zustand";
import { devtools } from "zustand/middleware";
import wolkHelper from "../common/WolkHelper";

const serverUrl = process.env.REACT_APP_SERVER_URL || "http://localhost";

let useStore = (set, get) => ({
  loading: true,
  hasProfile: false,
  profile: {},
  rhymeMoods: [
    { slug: "gewoon", title: "Gewoon" },
    { slug: "verliefd", title: "Verliefd" },
    { slug: "enthousiast", title: "Enthousiast" },
    { slug: "verdrietig", title: "Verdrietig" },
    { slug: "nostalgisch", title: "Nostalgisch" },
    { slug: "duizelig", title: "Duizelig" },
    { slug: "verlegen", title: "Verlegen" },
    { slug: "boos", title: "Boos" },
    { slug: "melig", title: "Melig" },
    { slug: "eenzaam", title: "Eenzaam" },
  ],
  rhymeTypes: [
    {
      slug: "sonnet",
      title: "Sonnet",
      scheme: "ABBACDDCEFEFEF",
      rythm: [{ title: "kwatrijn" }],
      dynamic: false,
      required: ["rhymeComplete", "title", "profileName"],
    },
    { slug: "elfje", title: "Elfje", scheme: false, dynamic: false },
    { slug: "limerick", title: "Limerick", scheme: "AABBA", dynamic: false },
    { slug: "haiku", title: "Haiku", scheme: false, dynamic: false },
    { slug: "vrij", title: "Vrij", scheme: false, dynamic: true },
  ],
  saveProfile: async (profile) => {
    let stateProfile = get().profile;
    await wolkHelper
      .saveProfile({ ...stateProfile, ...profile })
      .then((response) => {
        set((state) => {
          return {
            ...state,
            isNew:false,
            profile: response,
          };
        });
      });
  },
  rhymes: [

  ],
  getRhymeById: (id) => {
    const rhyme = get().rhymes.find((rhyme) => rhyme.id === id);
    return { a: "b" };
  },
  removeRhyme: async (id) => {
    await wolkHelper.removeRhyme(id).then((response) => {
      // remove from state
      set((state) => {
        let stateRhymes = [...state.rhymes];
        let index = stateRhymes.findIndex((item) => item.id === id);

        stateRhymes.splice(index, 1);
        return {
          ...state,
          rhymes: [...stateRhymes],
        };
      });
    });
  },
  addRhyme: (rhyme) => {
    set((state) => {
      return {
        ...state,
        rhymes: [...state.rhymes, rhyme],
      };
    });
  },

  patchProfile: async (id, profile) => {
    await wolkHelper.patchProfile(id, profile).then((response) => {
      // store the rhyme
      set((state) => {
        return {
          ...state,
          profile: response,
        };
      });
    });
  },
  patchRhyme: async (id, rhyme) => {
    let data = { title: rhyme.title, lines: rhyme.lines };

    await wolkHelper.patchRhyme(id, data).then((response) => {
      // store the rhyme
      set((state) => {
        let stateRhymes = [...state.rhymes];
        let index = stateRhymes.findIndex((item) => item.id === response.id);
        stateRhymes[index] = response;
        return {
          ...state,
          rhymes: stateRhymes,
        };
      });
    });
  },
  saveRhyme: async (rhyme) => {
    const profile = get().profile;

    let data = { ...rhyme, owner: profile.uuid };

    await wolkHelper.saveRhyme(data).then((response) => {
      // store the rhyme
      set((state) => {
        return {
          ...state,
          rhymes: [...state.rhymes, response],
        };
      });
    });
  },
  fetch: async (pond) => {
    const rhymes = await wolkHelper.fetchRhymes();
    set({ rhymes: rhymes });
  },
});

//
useStore = devtools(useStore);

export default useStore = create(useStore);
