// https://codesandbox.io/s/dialogprovider-bz1j1?file=/src/DialogProvider.tsx:1122-1366
import React, { useContext, useEffect, useRef, useState } from "react";
import DialogOverlay from "./DialogOverlay";
import useOnClickOutside from "use-onclickoutside";

const DialogContext = React.createContext([() => {}, () => {}]);


// -- Dialog Free -------------------------------------------------------------------------------------------


function DialogFree({
  open,
  text,
  children,
  onClose,
}) {
  const dialogRef = useRef();

  // when clicking outside the editor, remove the active line
  useOnClickOutside(dialogRef, (ev) => {
    onClose();
  });
  const onAnswer = (answer) => {
    onClose();
  };
  return (
    <>
  
      {open && (
        <div className="dialog-wrapper">
          <div className="dialog-wrapper__backdrop"></div>
          <div className="dialog" ref={dialogRef}>
            <div className="dialog__wrapper">
              <header className="dialog__header"></header>
              <div className="dialog__content">{children}</div>
              <footer className="dialog__footer">
   
              </footer>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

// -- Dialog Prompt -------------------------------------------------------------------------------------------

function DialogPrompt({
  open,
  text,
  disableCancel,
  textCancel,
  textProceed,
  onConfirm,
  onCancel,
  onClose,
}) {

  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef();

  const dialogRef = useRef();

  // when clicking outside the editor, remove the active line
  useOnClickOutside(dialogRef, (ev) => {
    onClose();
  });

  const onAnswer = (answer) => {
    if (answer) {
      if (onConfirm) onConfirm(inputValue);
    } else {
      if (onCancel) onCancel();
    }
    onClose();
  };
  
  return (
    <>
      {open && (
        <div className="dialog-wrapper">
          <div className="dialog-wrapper__backdrop"></div>
          <div className="dialog" ref={dialogRef}>
            <div className="dialog__wrapper">
              <header className="dialog__header"></header>
              <div className="dialog__content">
                <p>{text}</p>
                <input type="text" defaultValue={inputValue} onChange={(evt) => setInputValue(evt.target.value)}/>
              </div>
              <footer className="dialog__footer">
    
                {!disableCancel && (
                  <button
                    className="pill-button"
                    onClick={() => onAnswer(false)}
                  >
                    {textCancel}
                  </button>
                )}
                <button
                  className="pill-button is-primary"
                  onClick={() => onAnswer(true)}
                >
                  {textProceed}
                </button>
              </footer>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
// -- Dialog Notice -------------------------------------------------------------------------------------------

function DialogNotice({
  open,
  text,
  children,
  textProceed,
  onClose,
}) {

  const dialogRef = useRef();

  // when clicking outside the editor, remove the active line
  useOnClickOutside(dialogRef, (ev) => {
    onClose();
  });

  return (
    <>
      {open && (
        <div className="dialog-wrapper">
          <div className="dialog-wrapper__backdrop"></div>
          <div className="dialog" ref={dialogRef}>
            <div className="dialog__wrapper">
              <header className="dialog__header"></header>
              <div className="dialog__content">
              {!children && text}
                {children && children}

              </div>
              <footer className="dialog__footer">
                <button
                  className="pill-button is-primary"
                  onClick={onClose}
                >
                  {textProceed}
                </button>
              </footer>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
// -- Dialog Confirm -------------------------------------------------------------------------------------------

function DialogConfirm({
  open,
  text,
  disableCancel,
  textCancel,
  textProceed,
  onConfirm,
  onCancel,
  children,
  onClose,
}) {

  const dialogRef = useRef();

  // when clicking outside the editor, remove the active line
  useOnClickOutside(dialogRef, (ev) => {
    onClose();
  });

  const onAnswer = (answer) => {
    if (answer) {
      if (onConfirm) onConfirm();
    } else {
      if (onCancel) onCancel();
    }
    onClose();
  };
  return (
    <>
      {open && (
        <div className="dialog-wrapper">
          <div className="dialog-wrapper__backdrop"></div>
          <div className="dialog" ref={dialogRef}>
            <div className="dialog__wrapper">
              <header className="dialog__header"></header>
              <div className="dialog__content">
                {!children && text}
                {children && children}
             
                </div>
              <footer className="dialog__footer">
              {!disableCancel && (
                  <button
                    className="pill-button"
                    onClick={() => onAnswer(false)}
                  >
                    {textCancel}
                  </button>
                )}
                <button
                  className="pill-button is-primary"
                  onClick={() => onAnswer(true)}
                >
                  {textProceed}
                </button>
 
              </footer>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default function DialogProvider({ children }) {
  const [dialogs, setDialogs] = useState([]);

  const createPromptDialog = (data) => {
    const dialog = {
      type: data.type,
      text: data.text,
      onConfirm: data.onConfirm || false,
      onCancel: data.onCancel || false,
      textCancel: data.textCancel || "nee",
      textProceed: data.textProceed || "ok",
      disableCancel: data.disableCancel || false,
      open: true,
    };

    return dialog;
  };

  const createConfirmDialog = (data) => {
    const dialog = {
      type: data.type,
      text: data.text,
      children:data.children || false,
      onConfirm: data.onConfirm || false,
      onCancel: data.onCancel || false,
      textCancel: data.textCancel || "nee",
      textProceed: data.textProceed || "ok",
      disableCancel: data.disableCancel || false,
      open: true,
    };

    return dialog;
  };

  const createNoticeDialog = (data) => {
    const dialog = {
      type: data.type,
      children:data.children || false,
      text: data.text,
      textCancel: data.textCancel || "nee",
      textProceed: data.textProceed || "ok",
      open: true,
    };

    return dialog;
  };

  const createFreeDialog = (data) => {
    const dialog = {
      type: 'free',
      open: true,
    }
    return dialog
  }

  const createDialog = (data) => {
    var dialog;
    if (data.type === "confirm") {
      dialog = createConfirmDialog(data);
    } else if (data.type === "notice") {
      dialog = createNoticeDialog(data);
    } else if (data.type === "prompt") {
      dialog = createPromptDialog(data);
    } else {
      dialog = createFreeDialog(data);
    }
    setDialogs((dialogs) => [...dialogs, dialog]);
  };

  const closeDialog = () => {
    setDialogs((dialogs) => {
      var tmpDialogs = [...dialogs]
      const latestDialog = tmpDialogs.pop();
      if (!latestDialog) return dialogs;
      if (latestDialog.onClose) latestDialog.onClose();
      return [...tmpDialogs];
    });
  };

  const contextValue = useRef([createDialog, closeDialog]);

  return (
    <>
      <DialogContext.Provider value={contextValue.current}>
        {children}
        {dialogs.map((dialog, i) => {
          return (
            <DialogOverlay key={`key-${i}`}>
              {dialog.type === "confirm" && (<DialogConfirm {...dialog} onClose={closeDialog} />)}
              {dialog.type === "prompt" && (<DialogPrompt {...dialog} onClose={closeDialog} />)}
              {dialog.type === "notice" && (<DialogNotice {...dialog} onClose={closeDialog} />)}
              {dialog.type === "free" && (<DialogFree {...dialog} onClose={closeDialog} />)}
            </DialogOverlay>
          );
        })}
      </DialogContext.Provider>
    </>
  );
}

export const useDialog = () => useContext(DialogContext);
