import { createPortal } from "react-dom";
import { useRef, useEffect, useState } from "react";

const DialogOverlay = ({ children }) => {
  const popupRoot = document.getElementById("dialog-root");
  const el = useRef(document.createElement("div"));

  useEffect(() => {
    if (popupRoot) {
      popupRoot.appendChild(el.current);
    }
    return () => {
      if (popupRoot) popupRoot.removeChild(el.current);
    };
  }, [popupRoot]);

  return createPortal(children, el.current);
};

export default DialogOverlay;
