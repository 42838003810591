import logo from "./logo.svg";
import "./styles/main.scss";
// import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Cookies from "universal-cookie";


import wolkHelper from "./common/WolkHelper";
import useStore from "./store/store";
import { useDialog } from "./components/Dialog/DialogProvider";
import DialogProvider from "./components/Dialog/DialogProvider";

const editorUrl = "https://wolkapp.nl/editor/";
console.log(window.location.origin);
wolkHelper.setApiUrl(
  process.env.REACT_APP_SERVER_URL || window.location.origin
);

const baseUrl = process.env.REACT_APP_BASE_URL || "/";


// -- RhymesList ----------------------------------------------------------------------------------------------
function RhymesList() {
  const rhymes = useStore((state) => state.rhymes);
  const removeRhyme = useStore((state) => state.removeRhyme);
  const [openDialog, closeDialog] = useDialog();

  function NoRhymes() {
    return (<div>Nog geen gedichten.</div>)
  }

  const RemoveRhyme = (id) => {
    openDialog({
      type: "confirm",
      text: "Weet je zeker dat je dit gedicht wilt verwijderen?",
      textProceed: "Ja, ik weet het zeker",
      textCancel: "nee",
      onConfirm: () => {
        removeRhyme(id);
      },
      onCancel: () => {},
    });
  }

  function Rhymes(){
    return (rhymes &&
      rhymes.length &&
      rhymes.map((rhyme, index) => {

        let isUntitled = (rhyme.title === "")
        let title = rhyme.title
        if (isUntitled) {
          title = "Naamloos";
        }
        return (
          <li key={index} className={`rhymes-list__row rhyme-row ${isUntitled ? 'is-untitled' : ''}`}>
            <a href={`${editorUrl}gedicht/${rhyme.id}`}><span>{title}</span></a>
            
            <div className="rhyme-row__tools">
              <button className="rhyme-row__edit" onClick={() =>  {window.location.href=`${editorUrl}gedicht/${rhyme.id}`} }><i className="bi-pencil"></i></button>
              <button className="rhyme-row__view" onClick={() =>  {window.location.href=`https://wolkapp.nl/${rhyme.id}`} }><i className="bi-eye"></i></button>
              <button className="rhyme-row__delete" onClick={() => RemoveRhyme(rhyme.id)}><i className="bi-trash3"></i></button>
            </div>
          </li>
        );
      }))
  }
  return (
    <ul className="rhymes-list">
        {rhymes && rhymes.length > 0 && (<Rhymes></Rhymes>)}
        {!rhymes || !rhymes.length && (<NoRhymes></NoRhymes>)}
    </ul>
  );
}



function ShareDialog({profile}) {
  const [openDialog, closeDialog] = useDialog();

  const profileLink = `wolkapp.nl/?id=${profile.uuid}`;
  const hasShare = (navigator.share !== undefined);

  function copyToClipboard() {
    navigator.clipboard.writeText(`https://${profileLink}`);

      openDialog({
        type: "notice",
        textProceed: "sluiten",
        text: "De link is gekopieerd naar je klembord",
        onConfirm: () => {
        },
        onCancel: () => {},
      });
  }

  const openSharePanel = () => {
    const shareData = {
      url: `https://${profileLink}`,
    };

    navigator.share(shareData).then((data) => {});
  }

  return (
    <div className="dialog-content dialog-content--save-profile">
     <h3>Zo houdt je altijd toegang tot je gedichten:</h3>
      <p>Je kunt je gedichten altijd bewerken en delen. Ook als je later een andere computer gebruikt.</p>

      <h4>Bewaar deze link:</h4>
      <div className="profile-link">
        <a href={`wolkapp.nl/?id=${profile.uuid}`} target="_blank" rel="noreferrer">wolkapp.nl/?id=<span>{profile.uuid}</span></a><button onClick={() => copyToClipboard()}><i className="bi-clipboard"></i></button>
      </div>

      <h4>Of email jezelf de link:</h4>
      <a href={`mailto:?subject=Link naar mijn gedichten&body=Ik heb een account aangemaakt op wolkapp.nl. Hiermee kan ik mijn gedichten bewaren en delen. De link naar mijn account is wolkapp.nl/?id=${profile.uuid}`} className="button button--secondary">Stuur email</a>
      {/* {hasShare && (
        <>
          <h4>Of deel de link met een applicatie:</h4>
          <button onClick={() => openSharePanel()}>open apps</button>
        </>
      )} */}
    </div>
)
}

function ManageProfile() {

  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const [openDialog, closeDialog] = useDialog();

  const profile = useStore((state) => state.profile);
  if (profile.isNew) return;

  
  function DeleteContent() {
    return (<div className="dialog-content dialog-content--delete-profile"><h3>Weet je zeker dat je dit account wilt verwijderen?</h3> <p>Je verwijderd het account enkel van dit apparaat.</p><p>Als je de link niet bewaard hebt kun je je account niet meer bereiken</p></div>)
  } 


  const linkProfile = () => {
    openDialog({
      type: "notice",
      textProceed: "sluiten",
      children: <ShareDialog profile={profile}/>,
      onConfirm: () => {
        closeDialog()
        console.log('confirme')
        // removeRhyme(rhyme.id);
        // navigate("/");
      },
      onCancel: () => {},
    });
  }


  const deleteProfile = () => {
    openDialog({
      type: "confirm",
      text: "Weet je zeker dat je je account wilt verwijderen?",
      textProceed: "Ja, ik weet het zeker",
      textCancel: "nee",
      children: <DeleteContent/>,
      onConfirm: () => {
        wolkHelper.deleteProfile(profile);
        // removeRhyme(rhyme.id);
        // navigate("/");
      },
      onCancel: () => {},
    });
  }
  
  return (
    <div className="profile-manage">
      <button onClick={() => linkProfile()}><i className="bi-link"></i></button>
      <button onClick={() => deleteProfile()}><i className="bi-trash3"></i></button>

   
    </div>
  );
}

// --------------------------------------------------------------------------------------- //d
function ProfileName() {
  const saveProfile = useStore((state) => state.saveProfile);
  const profile = useStore((state) => state.profile);
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(profile.name);
  }, [profile]);

  const onChange = (event) => {
    setValue(event.target.value);
  };

  const onDeselect = () => {
    // persist the name
    saveProfile({ name: value });
  };
  return (
    <div>
      <input
        type="text"
        placeholder="Type hier je naam"
        defaultValue={value}
        onChange={onChange}
        onBlur={onDeselect}
      />
    </div>
  );
}

function WolkLogin() {
  const profile = useStore((state) => state.profile);
  const [openDialog, closeDialog] = useDialog();

  const showLogin = () => {
    
    openDialog({
      type: "prompt",
      text: "Vul je account code in",
      textProceed: "Inloggen",
      textCancel: "annuleren",
      onConfirm: (loginCode) => {
        let sanitezedLoginCode = loginCode;
        //  sanitize the logincode by only capturing the characters from the '=' sign till the end of the string.
        //  if it even contains a '=' sign and also strip any spaces
        if (loginCode && loginCode.match(/=(.*)/)) {
           sanitezedLoginCode = loginCode.match(/=(.*)/)[1].replace(/\s/g, '');
        } 
  
        wolkHelper.fetchProfile(sanitezedLoginCode).then((profile) => {
          if (!profile) {
            openDialog({type: "notice", text: "Deze code is niet geldig", textProceed: "ok"})
            return;
          }

          wolkHelper.setCookie(sanitezedLoginCode);
          window.location.reload();
        });
      },
    });
  }

  return (<>{profile.isNew && (<p>Heb je al een account? <button onClick={() => showLogin()}>login</button></p>)}</>)

}

// --------------------------------------------------------------------------------------- //d

function App() {
  const saveProfile = useStore((state) => state.saveProfile);

  const profile = useStore((state) => state.profile);

  const loadRef = useRef(null);

  const setProfileUrl = () => {
    if (!profile.uuid) return;
    let pageUrl = window.location.href;
    let url = new URL(pageUrl);
    url.searchParams.set('id',profile.uuid);
    window.history.replaceState({}, false, url.href);
  }

  useEffect(() => {
    if (profile.isNew) {
      document.body.classList.add('is-new-user');
    }

    return () => {
      document.body.classList.remove('is-new-user');
    }
  }, [profile])

  // run this loading procedure only once
  useEffect(() => {
    if (loadRef.current) return;
    loadRef.current = true;

    wolkHelper
      .initializeProfile()
      .then((profile) => {
        useStore.setState({ profile: profile });

        // fetch the rhymes based on profile uuid
        if (!profile.uuid) return;
        return wolkHelper.fetchRhymes(profile.uuid);
      })
      .then((rhymes) => {
        // if there are rhymes load these to state
        if (rhymes) {
          useStore.setState({ rhymes: rhymes });
        }
        useStore.setState({ loading: false });
      });
    return () => {};
  }, []);


  useEffect(() => {
    if (profile) {
      setProfileUrl();
    }
  }, [profile])


  const onNewRhyme = async () => {
    if (profile.isNew) {
      await saveProfile(profile);
      wolkHelper.setCookie(profile.uuid);
      window.location.href=`${editorUrl}gedicht/`
    } else {
      window.location.href=`${editorUrl}gedicht/`
    }
  }
 
  return (
    <>
      <DialogProvider>
      <div className="wolk-dashboard__profile">
        <ProfileName></ProfileName>
        <ManageProfile></ManageProfile>
      </div>

      <div className="wolk-dashboard__rhyme">
        <h3>Mijn gedichten</h3>
        <button onClick={onNewRhyme }>Nieuw gedicht</button>
        <RhymesList></RhymesList>
      </div>

      <div className="wolk-dashboard__footer">
        <WolkLogin> </WolkLogin>
      </div>
    
      <div id="notification-root"></div>
          <div id="dialog-root"></div>
      </DialogProvider>
    </>
  );
}

export default App;
