import cookie from "cookiejs";

class WolkHelper {
  constructor() {
    this.cookieId = "wolk_profile";
    // this.options = options;
    // this.apiUrl = options.apiUrl;
  }

  async initializeProfile() {
    // the app works around profile ids
    // if this is a first time visit there might not be a profile id
    const createProfileId = () => {
      return this.createProfileId().then((response) => {
        // console.log(response);
        this.setCookie(response.uuid);
        // fetchRhymes();
        return { uuid: response.uuid, isNew:true };
      });
    };

    // main loading part which handles the loading and the setting of cookies etc
    let mainPromiseChain;

    // check if the url contains an id
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // check if the profile id is stored in a cookie
    let profileId = urlParams.get("id") || wolkHelper.getCookie();
    // check if there is a profile id
    if (!profileId) {
      mainPromiseChain = createProfileId();
    } else {
      mainPromiseChain = wolkHelper.fetchProfile(profileId).then((response) => {
        if (!response) {
          return createProfileId();
        }
        return response;
      });
    }

    mainPromiseChain.then((response) => {
      // make sure the loaded profile is stored in a cookie
      wolkHelper.setCookie(response.uuid);

      // store the profile if there is any, otherwise only store the uuid
      let profile = {};
      if (response.uuid) {
        profile = response;
        // useStore.setState({ profile: profile });
      }
      return profile;
    });

    return mainPromiseChain;
  }

  removeProfileFromBrowser() {
    cookie.remove(this.cookieId)
    localStorage.removeItem(this.cookieId)

    // reload
    let url = new URL(window.location.href);
    window.location.href = url.origin;
  }

  deleteProfile(id) {

        const response = this.performFetch({
      endpoint: "/api/editor/profiles/" + id + "/delete",
      method: "POST",
    }).then((response) => {
      this.removeProfileFromBrowser();

    })

    // return response;

  }

  // loginProfile(id) {

  //   return fetchProfile(id);
  //   console.log(id)
  //   console.log('do login');
  //   // setCookie(id)
  // }

  setApiUrl(apiUrl) {
    this.apiUrl = apiUrl;
  }

  getCookie() {
    // return cookie.get(this.cookieId);
    return cookie.get(this.cookieId) || localStorage.getItem(this.cookieId);
  }

  setCookie(id) {
    cookie.set(this.cookieId, id, { expires: 365 });
    localStorage.setItem(this.cookieId, id);

    // cookie.set(this.cookieId, id);
  }

  async createProfileId(options = {}) {
    const response = this.performFetch({
      endpoint: "/api/editor/profiles/random-uuid",
      method: "GET",
      options: options,
    });
    return response;
  }

  async performFetch(args) {
    let options = {
      method: "GET",
    };

    options = Object.assign(options, args);

    if (options.data) {
      options.body = JSON.stringify(args.data);
    }

    const response = fetch(this.apiUrl + args.endpoint, options)
      .then((response) => response.json())
      .then((data) => data);

    return response;
  }

  async findRhymingWords(word) {
    const response = this.performFetch({
      endpoint: "/api/editor/find-words/" + encodeURIComponent(word),
      method: "GET",
    });
    return response;
  }

  // -- profileTools ---
  async fetchProfile(id) {
    const response = this.performFetch({
      endpoint: "/api/editor/profiles/" + id,
    });
    return response;
  }

  async saveProfile(profile) {
    const response = this.performFetch({
      endpoint: "/api/editor/profiles/" + profile.uuid,
      method: "POST",
      data: profile,
    });
    return response;
  }

  async patchProfile(id, data) {
    const response = this.performFetch({
      endpoint: "/api/editor/profile/" + id,
      method: "POST",
      data: data,
    });
    return response;
  }

  // -- rhymeTools ---
  async fetchRhymes(id) {
    const response = this.performFetch({
      endpoint: "/api/editor/rhymes-by-profile/" + id,
    });
    return response;
  }

  async saveRhyme(rhyme) {
    let method = rhyme.id ? "UPDATE" : "POST";
    const response = this.performFetch({
      endpoint: "/api/editor/rhymes",
      method,
      data: rhyme,
    });
    return response;
  }

  async patchRhyme(id, rhyme) {
    const response = this.performFetch({
      endpoint: "/api/editor/rhymes/" + id,
      method: "POST",
      data: rhyme,
    });
    return response;
  }
  async removeRhyme(id) {
    const response = this.performFetch({
      endpoint: "/api/editor/rhymes/" + id + "/delete",
      method: "POST",
    });

    return response;
  }
}

const wolkHelper = new WolkHelper();

export default wolkHelper;
